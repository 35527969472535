import { createWebHistory, createRouter } from "vue-router";
// import HomeView from "./components/HomeView.vue";

const routes = [
  {
    path: "/",
    component: {},
    meta: { RedirectExternalUrl: "https://mitralab.io" },
  },
  {
    path: "/pt/solucoes-process-owners",
    component: {},
    meta: {
      RedirectExternalUrl: "https://www.mitralab.io/pt/solucoes-process-owners",
    },
  },
  {
    path: "/pt/solucoes-financas",
    component: {},
    meta: {
      RedirectExternalUrl: "https://www.mitralab.io/pt/solucoes-financas",
    },
  },
  {
    path: "/pt/solucoes-desenvolvedores/",
    component: {},
    meta: {
      RedirectExternalUrl:
        "https://www.mitralab.io/pt/solucoes-desenvolvedores",
    },
  },
  {
    path: "/pt/solucoes-analytics/",
    component: {},
    meta: {
      RedirectExternalUrl: "https://www.mitralab.io/pt/solucoes-analytics",
    },
  },
  {
    path: "/pt/blog/",
    component: {},
    meta: { RedirectExternalUrl: "https://www.mitralab.io/pt/blog" },
  },
  {
    path: "/pt/planos-e-precos/",
    component: {},
    meta: { RedirectExternalUrl: "https://www.mitralab.io/pt/planos-e-precos" },
  },
  {
    path: "/:catchAll(.*)",
    component: {},
    meta: { RedirectExternalUrl: "https://www.mitralab.io" },
  },
  {
    path: "/solucoes-process-owners",
    component: {},
    meta: {
      RedirectExternalUrl: "https://www.mitralab.io/pt/solucoes-process-owners",
    },
  },
  {
    path: "/solucoes-financas",
    component: {},
    meta: {
      RedirectExternalUrl: "https://www.mitralab.io/pt/solucoes-financas",
    },
  },
  {
    path: "/solucoes-desenvolvedores",
    component: {},
    meta: {
      RedirectExternalUrl:
        "https://www.mitralab.io/pt/solucoes-desenvolvedores",
    },
  },
  {
    path: "/solucoes-analytics",
    component: {},
    meta: {
      RedirectExternalUrl: "https://www.mitralab.io/pt/solucoes-analytics",
    },
  },
  {
    path: "/blog",
    component: {},
    meta: {
      RedirectExternalUrl: "https://www.mitralab.io/pt/blog",
    },
  },
  {
    path: "/planos-e-precos",
    component: {},
    meta: {
      RedirectExternalUrl: "https://www.mitralab.io/pt/planos-e-precos",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, _from, next) => {
  if (to.matched.some((record) => record.meta.RedirectExternalUrl)) {
    const url = to.meta.RedirectExternalUrl;
    window.location.replace(url);
    return;
  }

  next();
});

export default router;
